import { DocumentVersion } from "../document/DocumentVersion";

export interface Product
{
    id?: string;
    companyId?: string;
    name: string;
    number?: number;
    code: string;
    description?: string;
    descriptionStorageId?: string;
    originCountry: ProductCountry;
    ingredients?: ProductIngredient[];
    nutritionalTableElements?: ProductNutritionalTableElement[];
    symbols?: ProductSymbol[];
    referenceCodes?: ProductReferenceCode[];
    archived?: boolean;
    creationTimestampUtc?: Date;
    lastModificationTimestampUtc?: Date;
    deleteTimestampUtc?: Date;
    createdById?: string;
    updatedById?: string;
    previewUrl?: string;
    visualizerUrl?: string;
    productDocumentVersions?: ProductDocumentVersion[];
}

export enum ProductCountry
{
    // TODO: Define this list
    Alemania = 'Alemania',
    Argentina = 'Argentina',
    Australia = 'Australia',
    Austria = 'Austria',
    Brasil = 'Brasil',
    Chile = 'Chile',
    China = 'China',
    Espana = 'España',
    Estados_Unidos = 'Estados Unidos',
    Francia = 'Francia',
    Grecia = 'Grecia',
    Hungria = 'Hungría',
    India = 'India',
    Italia = 'Italia',
    Japon = 'Japón',
    Mexico = 'México',
    Nueva_Zelanda = 'Nueva Zelanda',
    Peru = 'Perú',
    Portugal = 'Portugal',
    Rumania = 'Rumanía',
    Suiza = 'Suiza',
    Sudafrica = 'Sudáfrica',
    Uruguay = 'Uruguay'
}

export interface Nutritional
{
    name: string;
    type: string;
}

export interface Ingredient
{
    id?: string;
    /**
     * SHOULD NOT BE USED DIRECTLY. Translate it first (lid.srv.Ingredient.name)
     */
    name: string;
    type?: IngredientType;
    typeId?: string;
    required?: boolean;

    // UI Only
    translatedName?: string;
}

export interface IngredientType
{
    id?: string;
    /**
     * SHOULD NOT BE USED DIRECTLY. Translate it first (lid.srv.IngredientType.name)
     */
    name: string;
    parentTypeId?: string;
    parentType?: IngredientType;
    childTypes?: IngredientType[];

    // UI Only
    ingredients?: Ingredient[];
    translatedName?: string
}

export interface NutritionalTableElement
{
    id: string;
    /**
     * SHOULD NOT BE USED DIRECTLY. Translate it first (lid.srv.NutritionalTableElement.name)
     */
    name: string;
    order?: number;
    groupLevel?: number;
    unitOfMeasurement?: 'Energy' | 'Weight';

    // UI Only
    productNutritionalTableElement?: ProductNutritionalTableElement;
    translatedName?: string
}

export enum EnergyUnit
{
    kJ = 'kJ',
    kcal = 'kcal'
}

export enum WeightUnit
{
    g = 'g',
    mg = 'mg'
}

export interface ProductIngredient
{
    productId?: string;
    ingredient: Ingredient;
    ingredientId?: string;
    order: number;
}

export interface ProductNutritionalTableElement
{
    productId?: string;
    nutritionalTableElementId?: string;
    nutritionalTableElement: NutritionalTableElement;
    value: number; //75 ejemplo
    unitOfMeasurement: EnergyUnit | WeightUnit; //g. kJ

    // UI Only (and only if nutritionalTableElement.unitOfMeasurement == Energy)
    convertedValue?: number;
    convertedUnitOfMeasurement?: EnergyUnit;
}

export interface ProductReferenceCode
{
    companyId?: string;
    productId?: string;
    code: string;
}

export interface ProductSymbol
{
    id?: string;
    fileName: string;
    previewUrl: string;
}

export interface ProductDocumentVersion
{
    documentVersionId?: string;
    documentVersion: DocumentVersion;
    productId?: string;
    product: Product;

    libraryDocumentId?: string;
    libraryDocument?: Document;

    productVersionUrl?: string;
}
