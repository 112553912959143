import { SubscriptionPlan } from '../subscriptionPlans/SubscriptionPlan';

export enum ConfigurableLimit
{
    STORAGE_USAGE,
    TOTAL_VERSIONS,
    TOTAL_DOCUMENTS,
    TOTAL_IMAGE_COMPARES,
    MONTHLY_IMAGE_COMPARES,
    TOTAL_PRODUCTS_QR
}

export interface CompanySubscriptionPlan
{
    subscriptionPlanId?: string;
    subscriptionPlan?: SubscriptionPlan
    hasTaskModule?: boolean;
    hasProductsModule?: boolean;
    hasAIModule?: boolean;
    hasIndicatorsModule?: boolean;
    hasApiAccess?: boolean;
    hasSupport?: boolean;
    hasImageCompare?: boolean;
    allowSetProductColor?: boolean;
    productQrLimitTotal?: number;
    versionsLimitTotal?: number;
    documentsLimitTotal?: number;
    usersLimitTotal?: number;
    externalReviewersLimitPerTask?: number;
    imageCompareLimitTotalPerMonth?: number;
    imageCompareLimitTotal?: number;
    sizeTotalLimitGb?: number;
    lastUpdateUserId?: string;
    lastUpdateTimestampUtc?: Date;
}

export interface CompanyStorageUsage
{
    companyId: string;
    documentsStorageUsage: number;
    libraryStorageUsage: number;
    imageComparesStorageUsage: number;
    productsStorageUsage: number;
    tasksStorageUsage: number;
    totalStorageUsage: number;
}
