import { Injectable } from '@angular/core';
import { UserFeedbackForm, UserFeedbackResponse } from '../public_api';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserFeedbackResponseService {


  constructor(private authService: AuthService)
  {
  }

  async postUserFeedbackResponse(response: UserFeedbackResponse)
  {
    await this.authService.authPost<UserFeedbackResponse>('/api/userfeedbackresponse/postFeedbackResponse', response);
    return;
  }

  async getUserLastFeedbackResponse(userFeedbackForm: UserFeedbackForm)
  {
    const params: [string, string][] = [
      ['formId', userFeedbackForm.id]
    ];
    const lastResponse = await this.authService.authGet<UserFeedbackResponse>('/api/userfeedbackresponse/getLastFeedbackResponse', params);
    if(lastResponse)
    {
      lastResponse.timestampUTC = new Date(lastResponse.timestampUTC);
    }
    return lastResponse;
  }
}
