
<div class="feedback-notifications">

    <!-- HEADS-UP MSG -->
    <ng-container *ngIf="style === 'soft warning'">
        <div class="toast heads-up">
            <div class="icon">
                <!-- TO DO - This icon has to change depending on the message -->
                <mat-icon class="notranslate heads-up-icon">wifi_off</mat-icon> 
            </div>
            <div class="text">
                <div class="message">
                    {{message}}
                </div>
                <!-- 
                TO DO - Implement custom actions depending on the type of feedback (styles are allready set)
                <div class="actions">
                    <button class="action-btn">Action 1</button>
                    <button class="action-btn">Action 2</button>
                </div>
                -->
            </div>    
            <div class="close">
                <button class="close-btn" (click)="dismiss()">
                    <mat-icon class="notranslate close-icon">cancel</mat-icon>
                </button>
            </div>
        </div>    
    </ng-container>

    <!-- WARNING MSG -->
    <ng-container *ngIf="style === 'warning'">
        <div class="toast warning">
            <div class="icon">
                <mat-icon class="notranslate warn-icon">warning</mat-icon>
            </div>
            <div class="text">
                <div class="message">
                    {{message}}
                </div>
                <!-- 
                TO DO - Implement custom actions depending on the type of feedback (styles are allready set)
                <div class="actions">
                    <button class="action-btn">Action 1</button>
                    <button class="action-btn">Action 2</button>
                </div>
                -->
            </div>    
            <div class="close">
                <button class="close-btn" (click)="dismiss()">
                    <mat-icon class="notranslate close-icon">cancel</mat-icon>
                </button>
            </div>
        </div>    
    </ng-container>


    <!-- ERROR MSG -->
    <ng-container *ngIf="style === 'severity warning'">
        <div class="toast error">
            <div class="icon">
                <mat-icon class="notranslate error-icon">error</mat-icon>
            </div>
            <div class="text">
                <div class="message">
                    {{message}}
                </div>
                <div class="actions" *ngIf="action">
                    <button class="action-btn" (click)="action.onClick(); dismiss()">{{action.buttonMessage}}</button>
                </div>

                <div class="contact-support-action actions" (click)="contactSupport(); dismiss()" *ngIf="!action">
                    <mat-icon class="notranslate support-icon">headset_mic</mat-icon>
                    <button class="action-btn">
                        <span class="contact-support"translate>lid.services.feedback-notifications.contactSupport</span>
                    </button>
                </div>

            </div>    
            <div class="close">
                <button class="close-btn" (click)="dismiss()">
                    <mat-icon class="notranslate close-icon">cancel</mat-icon>
                </button>
            </div>
        </div>    
    </ng-container>

     <!-- SUCCESS MSG -->
     <ng-container *ngIf="style === 'successfull'">
        <div class="toast success">
            <div class="icon">
                <mat-icon class="notranslate success-icon">check_circle</mat-icon>
            </div>
            <div class="text">
                <div class="message">
                    {{message}}
                </div>
                <!-- 
                TO DO - Implement custom actions depending on the type of feedback (styles are allready set)
                <div class="actions">
                    <button class="action-btn">Action 1</button>
                    <button class="action-btn">Action 2</button>
                </div>
                -->
            </div>    
            <div class="close">
                <button class="close-btn" (click)="dismiss()">
                    <mat-icon class="notranslate close-icon">cancel</mat-icon>
                </button>
            </div>
        </div>    
    </ng-container>   

</div>