import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReviewerNamingPipe } from './reviewer-naming.pipe';



@NgModule({
  declarations: [
    ReviewerNamingPipe
  ],
  imports: [
    CommonModule
  ],
  exports:
  [
    ReviewerNamingPipe
  ],
  providers:
  [
    ReviewerNamingPipe
  ]
})
export class ReviewerNamingModule { }
