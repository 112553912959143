import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-ask-dialog',
  templateUrl: './ask-dialog.component.html',
  styleUrls: ['./ask-dialog.component.scss']
})
export class AskDialogComponent
{
  theme: 'default' | 'pending-tasks' | 'recover-rule' = 'default';

  constructor(
    public dialogRef: MatDialogRef<AskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)
    {
      if (data.theme)
      {
        this.theme = data.theme;
      }
    }

  onNoClick(): void
  {
    this.dialogRef.close();
  }

  close()
  {
    this.dialogRef.close();
  }

}

