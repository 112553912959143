import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserFeedbackForm } from '../public_api';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserFeedbackFormService {

  constructor(private authService: AuthService)
  {
  }

  async getForms()
  {
    const forms: UserFeedbackForm [] = await this.authService.authGet<UserFeedbackForm []>('/api/userfeedbackform/getForms', []);
    for (const f of forms)
    {
      f.creationTimestampUTC = new Date(f.creationTimestampUTC);
      f.lastUpdateTimestampUTC = new Date(f.lastUpdateTimestampUTC);
    }
    return forms;
  }
}
