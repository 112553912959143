import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes} from '@angular/router';
import { PrivacyPolicyComponent } from './content/legals/components/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './content/legals/components/terms-and-conditions/terms-and-conditions.component';
import { DeviceGuard } from './content/guards/device-guard';
import { RootGuardComponent } from './content/guards/root-guard';

/**
 * URLs coming from outside (from extension or from email):
 * /reviewer/:reviewerId/:tasknumber (from email that was sent to a reviewer)
 * /company/:companyid/tasks/review/:tasknumber (from email that was sent to a user)
 * /company/:companyid/tasks/review/:tasknumber/subtask/:subtaskid (from extension)
 */

const extraOptionsParam: ExtraOptions =
{
  // enableTracing: true
};

const appRoutes: Routes =
[
  {
    path: 'web',
    loadChildren: () => import('./content/web2/web2.module').then(mod => mod.Web2Module),
    canLoad : [DeviceGuard]
  },
  {
    path: 'mobile',
    loadChildren: () => import('./content/mobile/mobile.module').then(mod => mod.MobileModule),
    canLoad : [DeviceGuard]
  },
  {
    path: 'account',
    loadChildren: () => import('./content/account/account.module').then(mod => mod.AccountModule)
  },
  {
    path: 'mssubscription',
    loadChildren: () => import('./content/account/account.module').then(mod => mod.AccountModule)
  },
  {
    path: 'legals',
    loadChildren: () => import('./content/legals/legals.module').then(mod => mod.LegalsModule)
  },
  {
    path: 'privacy',
    redirectTo: 'legals/privacy'
  },
  {
    path: 'termsandconditions',
    redirectTo: 'legals/termsandconditions'
  },
  {
    path: '**',
    component: RootGuardComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes, extraOptionsParam
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
