import { Injectable, EventEmitter } from '@angular/core';
import {
  PlatformInfoProvider,
  UserProvider,
  User,
  Reviewer,
  FrontendInfo,
  BackendInfo
} from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { UserDevice } from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/_models/user/UserDevice';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceProvider implements PlatformInfoProvider, UserProvider
{
  // these events are never fired in web, because they don't change after
  // inital load
  backendChanged: EventEmitter<void> = new EventEmitter<void>();

  constructor(private http: HttpClient, private translate: TranslateService)
  {
    console.log('AuthServiceProvider');
  }

  getCurrentUser(): Promise<User>
  {
    const userString = localStorage.getItem('user');
    if (!userString || userString === '' || userString === 'undefined')
    {
      return undefined;
    }
    else
    {
      return JSON.parse(userString);
    }
  }

  async storeUser(user: User): Promise<void>
  {
    localStorage.setItem('user', JSON.stringify(user));
  }

  async getLastCompanyId(): Promise<string>
  {
    const id = localStorage.getItem('companyId');
    return id;
  }

  async storeCompanyid(id: string)
  {
    localStorage.setItem('companyId', id);
  }

  async getLastReviewer(): Promise<Reviewer>
  {
    const reviewerString = localStorage.getItem('reviewer');
    if (!reviewerString || reviewerString === '' || reviewerString === 'undefined')
    {
      return undefined;
    }
    else
    {
      return JSON.parse(reviewerString);
    }
  }

  async storeReviewer(reviewer: Reviewer)
  {
    localStorage.setItem('reviewer', JSON.stringify(reviewer));
  }

  async getUserDevice(): Promise<UserDevice>
  {
    const userDevicerString = localStorage.getItem('userDevice');
    if (!userDevicerString || userDevicerString === '' || userDevicerString === 'undefined')
    {
      return undefined;
    }
    else
    {
      return JSON.parse(userDevicerString);
    }
  }

  async storeUserDevice(userDevice: UserDevice)
  {
    localStorage.setItem('userDevice', JSON.stringify(userDevice));
  }

  async getLogProperties(): Promise<{ [name: string]: string }>
  {
    const fi = await this.getFrontendInfo();
    let bi = await this.getBackendInfo();
    if (!bi)
    {
      bi = <any>{};
    }
    const properties =
    {
      frontendName: fi.frontendName,
      frontendLocale: fi.frontendLocale,
      frontendVersion: fi.frontendVersion,
      cloudName: bi.cloudName,
      cloudVersion: bi.version
    };
    return properties;
  }

  getBackendUrl(): string
  {
    return environment.backendUrl;
  }

  getWebUrl(): string
  {
    return location.origin;
  }

  async getFrontendInfo(): Promise<FrontendInfo>
  {
    const result: FrontendInfo =
    {
      frontendName: 'WEB', // todo get WEB|MOB|WEB2
      frontendLocale: this.translate.getBrowserLang(),
      frontendVersion: undefined, // todo add frontend build number to assets
    };
    return result;
  }

  async getBackendInfo(): Promise<BackendInfo>
  {
    const bi = await new Promise<BackendInfo>(async(res, rej) =>
    {
      this.http.post<BackendInfo>(`${this.getBackendUrl()}/api/test/ExtensionConnectionTest`, {})
        .subscribe(response => res(response), error => res(undefined));
    });
    return bi;
  }
}
