import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';

// @Injectable({
//     providedIn: 'root'
// })
@Component({
    selector: 'app-root-guard',
    template: `
    <div class="splash-screen">
      <img class="logo" src="assets/logowhite.svg" alt="MoonDesk logo">
    </div>`,
    styles:[
      `
     .splash-screen {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #0096fc;
        .logo {
            width: 200px;
        }
    }`]
  })
export class RootGuardComponent
{
    constructor(private authService: AuthService, private router: Router)
    {
        this.canActivate();
    }

    private async canActivate()
    {
        const href = window.location.pathname + window.location.search;
        let root = this.getRoot(href);
        let url = `${root}`; // ${window.location.origin}/
        if (!href.includes('/web/') && !href.includes('/desktop/') && !href.includes('/mobile/') && (href !== '/'))
        {
            url = `${root}${href}`; // ${window.location.origin}/
        }
        else
        {
            await this.authService.waitForInit();
            const identity = this.authService.getCurrentIdentity();
            let fallbackUrl: 'notidentified' | 'dashboard' | 'documents/list' | 'createworkspace' = 'notidentified';
            if (identity && identity.user)
            {
                if (identity.company)
                {
                    fallbackUrl = root === 'web' ? 'dashboard' : 'notidentified';
                }
                else
                {
                    root = 'account';
                    fallbackUrl = 'createworkspace';
                }
            }
            // To avoid load the whole web2 module justo to redirect to account module.
            url = fallbackUrl === 'notidentified' ? `/account/login` : `${root}/${fallbackUrl}`; // ${window.location.origin}/
        }
        window.location.replace(url);
        // this.router.navigate([url]);
        return false;
    }

    private getRoot(href: string): 'mobile' | 'web' | 'account'
    {
        const accountRegEx = /\/completeoauth\//g;
        if (accountRegEx.test(href))
        {
            return 'account';
        }
        else
        {
            return this.isTabletOrPhone() ? 'mobile' : 'web';
        }
    }

    private isTabletOrPhone(): boolean
    {
        const isMobile = (<any>window).isMobile;
        if (isMobile)
        {
            return isMobile.phone === true || isMobile.tablet === true;
        }
        return false;
    }
}
