import { environment } from '../../../environments/environment';
import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[e2eLocator]'
})
export class E2eLocatorDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) {
    if (environment.production)
    {
      renderer.removeAttribute(el.nativeElement, 'e2e');
    }
  }
}
