import { ClassValue } from '../configuration/Class';
import { DocType } from '../configuration/DocType';

export interface RuleClassValue
{
    classValue: ClassValue;
    not?: boolean;
}

export interface RuleDocumentType
{
    documentType: DocType;
}

export interface RuleClassSelection
{
    classId: string;
    classValuesIds: string[];
    not?: boolean;
}

export interface RuleFilter
{
    companyId?: string;
    documentTypesIds?: string[];
    classSelections?: RuleClassSelection[];
    filterBy?: RuleFilterType;
    orderBy?: RuleFilterOrderBy;
    reverseOrder?: boolean;
    searchText?: string;
    page?: number;
}

export enum RuleFilterType
{
    All,
    Enabled,
    Disabled
}

export enum RuleFilterOrderBy
{
    Number,
    LastModificationTimestamp
}
