import { Injectable } from '@angular/core';
import { AuthService, IndicatorsDashboard } from '../public_api';
import { IndicatorFilter } from '../_models/indicators/IndicatorFilter';

@Injectable({
  providedIn: 'root'
})
export class IndicatorDashboardService {

  constructor(
    private authService: AuthService
  ) { }
  async getIndicatorDashboard(indicatorFilter: IndicatorFilter): Promise<IndicatorsDashboard>
  {
    console.log('Filtering indicators by: ');
    console.log(indicatorFilter);

    const result = await this.authService.authPost<IndicatorsDashboard>('/api/indicators/getIndicatorsDashboard', indicatorFilter);
    return result;
  }
}
