export interface ProductFilter
{
    companyId?: string;
    orderBy?: ProductFilterOrderBy;
    reverseOrder?: boolean;
    status?: ProductFilterStatus;
    searchText?: string;
    page?: number;
    minCreationTimestampUtc?: Date;
    maxCreationTimestampUtc?: Date;
}

export enum ProductFilterOrderBy
{
    Number,
    CreationTimestampUtc,
}

export enum ProductFilterStatus
{
    All,
    Enabled,
    Disabled
}
