import { UserNamingPipe } from 'src/app/content/pipes/user-naming/user-naming.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations:
  [
    UserNamingPipe
  ],
  imports: [
    CommonModule
  ],
  exports:
  [
    UserNamingPipe
  ],
  providers:
  [
    UserNamingPipe
  ]
})
export class UserNamingModule { }
