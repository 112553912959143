import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AuthServiceProvider } from './auth-service-provider';
import { MoonDeskLibServiceModule } from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { OAuthService } from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
  ],
  exports: [
  ]
})
export class ServicesModule {
  static forRoot(): ModuleWithProviders<ServicesModule>
  {
    return {
      ngModule: ServicesModule,
      providers: [
        AuthServiceProvider,
        {provide: 'PlatformInfoProvider', useExisting: AuthServiceProvider},
        {provide: 'UserProvider', useExisting: AuthServiceProvider},
        MoonDeskLibServiceModule,
        OAuthService
      ]
    };
  }
}
